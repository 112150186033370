import React from "react";
import { Form, Input, DatePicker, Checkbox, Select, Space, Divider, Row, Col } from "antd";
import { v4 as uuid } from "uuid";
import { workersApi } from "contractors/api";
import { ResourceContext } from "common/form/context";
import { Attachment } from "common/form/attachment";
const { Option } = Select;

const WorkerForm = ({ form }) => {
  const workerId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: workerId, api: workersApi }}>
      <Form name="WorkerForm" form={form} autoComplete="off" layout="vertical">
        <Form.Item
          name="id"
          initialValue={workerId}
          hidden
        >
          <Input />
        </Form.Item>

        <Divider orientation="left">A - Identificación del Trabajador</Divider>
        <Space className="WorkerBasicInfo" align="middle" wrap>
          <Form.Item
            name="full_name"
            label="Nombre Completo"
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="birthdate"
            label="Fecha de Nacimiento"
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="job_position"
            label="Puesto"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="nss"
            label="NSS"
            style={{ width: "140px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="emergency_phone_number"
            label="Tel. Emergencia"
            style={{ width: "140px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="blood_type"
            label="Tipo de Sangre"
          >
            <Select showSearch allowClear>
              <Option value="A+">A+</Option>
              <Option value="A-">A-</Option>
              <Option value="B+">B+</Option>
              <Option value="B-">B-</Option>
              <Option value="O+">O+</Option>
              <Option value="O-">O-</Option>
              <Option value="AB+">AB+</Option>
              <Option value="AB-">AB-</Option>
            </Select>
          </Form.Item>
          <Attachment label="ID Oficial" name="government_id" />
          <Attachment label="Alta de Seguro Social" name="imss" />
          <Attachment label="Certificado Médico" name="health_certificate" />
          <Attachment label="Química Sanguínea" name="blood_tests" />
          <Attachment label="Documentos Responsivos" name="responsiblity_letters" />
        </Space>

        <Divider orientation="left">B - Historia Clínica</Divider>
        <Row>
          <Col span={6}>
            <Space direction="vertical">
              <Form.Item
                name="is_alcohol_consumer"
                valuePropName="checked"
              >
                <Checkbox>Consumo de Alcohol</Checkbox>
              </Form.Item>
              <Form.Item
                name="is_tobacco_consumer"
                valuePropName="checked"
              >
                <Checkbox>Consumo de Tabaco</Checkbox>
              </Form.Item>
              <Form.Item
                name="is_drug_user"
                valuePropName="checked"
              >
                <Checkbox>Consumo de Drogas</Checkbox>
              </Form.Item>
              <Form.Item
                name="is_immunized"
                valuePropName="checked"
              >
                <Checkbox>Inmunizado</Checkbox>
              </Form.Item>
              <Form.Item
                name="is_overweight"
                valuePropName="checked"
              >
                <Checkbox>Sobrepeso</Checkbox>
              </Form.Item>
              <Form.Item
                name="has_diabetes"
                valuePropName="checked"
              >
                <Checkbox>Diabetes</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical">
              <Form.Item
                name="has_hypertension"
                valuePropName="checked"
              >
                <Checkbox>Hipertensión Arterial</Checkbox>
              </Form.Item>
              <Form.Item
                name="has_tuberculosis"
                valuePropName="checked"
              >
                <Checkbox>Tuberculosis</Checkbox>
              </Form.Item>
              <Form.Item
                name="has_cancer"
                valuePropName="checked"
              >
                <Checkbox>Cancer</Checkbox>
              </Form.Item>
              <Form.Item
                name="has_cardiovascular_disease"
                valuePropName="checked"
              >
                <Checkbox>Cardiovascualares</Checkbox>
              </Form.Item>
              <Form.Item
                name="has_lungs_disease"
                valuePropName="checked"
              >
                <Checkbox>Pulmonares</Checkbox>
              </Form.Item>
              <Form.Item
                name="has_digestive_disease"
                valuePropName="checked"
              >
                <Checkbox>Digestivas</Checkbox>
              </Form.Item>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical">
              <Form.Item
                name="has_renal_disease"
                valuePropName="checked"
              >
                <Checkbox>Renales</Checkbox>
              </Form.Item>
              <Form.Item
                name="had_surgery"
                valuePropName="checked"
              >
                <Checkbox>Quirúrguicas</Checkbox>
              </Form.Item>
              <Form.Item
                name="other_medical_conditions"
                label="Otra(s)"
              >
                <Input />
              </Form.Item>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical">
              <Form.Item
                name="allergies"
                label="Alergias"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="current_medications"
                label="Medicación Actual"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="paternal_cronic_diseases"
                label="Enfermedades Crónicas del Padre"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="maternal_cronic_diseases"
                label="Enfermedades Crónicas de la Madre"
              >
                <Input />
              </Form.Item>
            </Space>
          </Col>
        </Row>

        <Divider orientation="left">C - Capacitación para Trabajos de Alto Riesgo</Divider>
        <Space className="WorkerTraining" align="middle" wrap>
          <Training name="Trabajos en Alturas" id="heights" />
          <Training name="Corte y Soldadura" id="cuttig_and_welding" />
          <Training name="Trabajos Eléctricos" id="electrical" />
          <Training name="Espacios Confinados" id="confined_spaces" />
          <Training name="Manejo de Cargas Suspendidas" id="suspended_loads" />
          <Training name="Sustancias Químicas Peligrosas" id="chemical" />
          <Training name="Temperaturas Extremas" id="extreme_temperatures" />
          <Training name="Plataformas de Elevación" id="lifting_platforms" />
          <Training name="Bloqueo y Etiquetado" id="lockout_tagout" />
        </Space>

        <Divider orientation="left">D - Capacitación Complementaria</Divider>
        <Space className="WorkerTraining" align="middle" wrap>
          <Training name="Maquinaria y Equipo" id="machinery_and_equipment" />
          <Training name="Maquinaria Pesada" id="heavy_machinery" />
          <Training name="Construcción y Excavación" id="construcion_and_excavation" />
          <Training name="Equipo de Protección Personal" id="personal_protection_equipment" />
          <Training name="Señalamientos de Seguridad" id="safety_signs" />
          <Training name="Iluminación" id="illumination" />
          <Training name="Ruido" id="noise" />
          <Training name="Áreas de Trabajo Seguras" id="safe_work_areas" />
          <Training name="Manejo de Extintores" id="fire_extinguisher" />
          <Training name="Primeros Auxilios" id="first_aid" />
        </Space>
      </Form>
    </ResourceContext.Provider>
  );
};

const Training = ({ id, name }) => (
  <Form.Item label={name}>
    <Space align="middle">
      <Attachment btnLabel="Seleccionar..." name={`${id}_training`} />
      <Form.Item name={`${id}_training_expires_at`}>
        <DatePicker placeholder="Expiración" />
      </Form.Item>
    </Space>
  </Form.Item>
);

export default WorkerForm;
