import React from "react"
import { orderBy } from "lodash";
import { crud } from "contractors/api";
import { Index } from "common/crud/index"
import { DateTime, DateAndTime } from "common/datetime";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Form from "./form"

const columns = [
  {
    title: "Proyecto",
    dataIndex: "project_name",
    key: "project_name"
  },
  {
    title: "Compañía/Departamento",
    dataIndex: "company_or_department",
    key: "company_or_departmen"
  },
  {
    title: "Tipo",
    key: "is_routine",
    render: (_, { is_routine }) => {
      if (is_routine == undefined)
        return "-"
      return is_routine ? "Rutinaria" : "No rutinaria"
    }
  },
  {
    title: "Fecha de Elaboración",
    key: "created_at",
    dataIndex: "created_at",
    render: (_, { created_at }) => (
      new DateTime(created_at).format()
    )
  },
  {
    title: "Fecha de Ejecución",
    key: "execution_expected_at",
    render: (_, { execution_expected_at__date: date, execution_expected_at__time: time }) => (
      (new DateAndTime(date, time)).format()
    )
  },
  {
    title: "Estado",
    key: "status",
    render: (_, { feedback: { is_approved } = {} }) => {
      if (is_approved == undefined || is_approved == null)
        return "-"
      return is_approved ? (
        <CheckCircleOutlined />
      ) : (
        <ExclamationCircleOutlined />
      );
    }
  },
];

export { columns };
export default () => (
  <Index
    columns={columns}
    dataSourceApi={crud("security_analyses")}
    sortFn={(data) => orderBy(data, "created_at", "desc")}
    ResourceForm={Form}
    modalWidth="85%"
    newLabel="Nuevo Análisis de Seguridad"
    editLabel="Editar Análisis de Seguridad"
  />
)
