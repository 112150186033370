import React, { useState } from "react";
import { csrf } from "contractors/api";
import { Form, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ResourceContext } from "common/form/context";

const Attachment = ({ label, name, ...props }) => (
  <ResourceContext.Consumer>
    {resource =>
      <Form.Item label={label} name={["attachments", name]} valuePropName="fileList">
        <ControlledUpload resource={resource} name={name} {...props} />
      </Form.Item>}
  </ResourceContext.Consumer>
)

const ControlledUpload = ({ name: attachment_id, uploadBtnLabel, resource, ...props }) => {
  const [fileList, setFileList] = useState(props.fileList || []);

  const updateFileList = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map(file => {
      if (file.response) {
        file.attachment_id = attachment_id;
        file.blob_id = file.response.blob.id;
        file.url = file.response.blob.url;
      }
      return file;
    });

    setFileList(fileList);
  }

  return <Upload
    maxCount={1}
    {...props}

    listType="text"
    fileList={fileList}
    onChange={updateFileList}

    action={resource.api.attachments.createOrUpdateUrl({ resource_id: resource.id, attachment_id })}
    data={{ attachment_id }}
    method="PATCH"
    headers={{ "X-CSRF-Token": csrf() }}

    onRemove={resource.api.attachments.destroy}
  >
    <Button icon={<UploadOutlined />}>
      {uploadBtnLabel || "Seleccionar Archivo"}
    </Button>
  </Upload>
}

const getFileList = (id, attachment) => (
  attachment.blob ? [{
    resource_id: id,
    attachment_id: attachment.id,
    blob_id: attachment.blob.id,
    uid: attachment.blob.id,
    url: attachment.blob.url,
    name: attachment.blob.filename,
    status: "done"
  }] : []
);

export { Attachment, getFileList }
