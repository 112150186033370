import React, { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { Table, Space, Modal, Button, Form, Spin, Skeleton } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { workersApi } from "contractors/api";
import WorkerForm from "./form";
import moment from "moment";
const { confirm } = Modal;

const byName = (a, b) => (
  ("" + a.full_name).localeCompare(b.full_name)
);

const Workers = () => {
  const [dataSource, setDataSource] = useState([]);
  const [editableWorkerId, setEditableWorkerId] = useState();
  const [createOrUpdateWorker, setCreateOrUpdateWorker] = React.useState(false);

  useEffect(() => {
    workersApi.index().then((workers) => {
      setDataSource(orderBy(workers, "created_at", "desc"))
    });
  }, []);

  const columns = [
    {
      title: "Nombre Completo",
      dataIndex: "full_name",
      key: "full_name"
    },
    {
      title: "Edad",
      key: "age",
      render: (_text, record) => (
        record.birthdate && moment().diff(record.birthdate, "years")
      ),
    },
    {
      title: "Puesto",
      dataIndex: "job_position",
      key: "job_position"
    },
    {
      title: "NSS",
      dataIndex: "nss",
      key: "nss"
    },
    {
      title: "Tel. Emergencia",
      dataIndex: "emergency_phone_number",
      key: "emergency_phone_number",
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_text, record) => (
        <Space size="middle">
          <a><EditOutlined onClick={() => startEditMode(record)} /></a>
          <a><DeleteOutlined onClick={() => handleDestroy(record)} /></a>
        </Space>
      ),
    },
  ];

  function startEditMode(record) {
    setEditableWorkerId(record.id);
    setCreateOrUpdateWorker(true);
  };

  function handleDestroy(record) {
    confirm({
      title: `Deseas eliminar al Trabajador?`,
      icon: <ExclamationCircleOutlined />,
      content: `- ${record.full_name}`,
      okType: "danger",
      okText: "Eliminar",
      cancelText: "Cancelar",
      transitionName: "",
      maskTransitionName: "",
      onOk() {
        workersApi.destroy(record.id).then(() => {
          setDataSource(dataSource.filter(worker => worker.id !== record.id))
        });
      }
    });
  };

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} rowKey="id" />
      <Button type="primary" onClick={() => setCreateOrUpdateWorker(true)}>
        Nuevo Trabajador
      </Button>
      {createOrUpdateWorker &&
        <CreateOrUpdateWorker
          id={editableWorkerId}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />}
    </div>
  );

  function handleCreate(formData) {
    setDataSource(prevDataSource =>
      [formData, ...prevDataSource].sort(byName)
    );
  }

  function handleUpdate(formData) {
    setDataSource(prevDataSource =>
      prevDataSource.map(worker => (
        worker.id === editableWorkerId ? formData : worker
      ))
    );
    setEditableWorkerId(undefined);
  }

  function handleCancel() {
    setCreateOrUpdateWorker(false);
    setEditableWorkerId(undefined);
  }
};

const CreateOrUpdateWorker = ({ id, ...props }) => {
  const [form] = Form.useForm();
  const [editableWorker, setEditableWorker] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (id) {
      workersApi.get(id).then((worker) => {
        if (worker.birthdate)
          worker.birthdate = moment(worker.birthdate);
        setEditableWorker(worker);
        form.setFieldsValue(worker);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    return () => {
      if (id) {
        setEditableWorker(undefined);
      }
      setIsLoading(false);
      setIsSaving(false);
    };
  }, []);

  const handleCreateOrUpdate = (formData) => {
    setIsSaving(true);

    const isUpdate = () => (
      !!editableWorker
    );

    return workersApi.createOrUpdate(formData).then((worker) => {
      setIsSaving(false);
      if (isUpdate()) {
        props.handleUpdate(formData);
      } else {
        props.handleCreate({ ...formData, id: worker.id });
      }
    });
  };

  const reset = () => {
    form.resetFields();
    setEditableWorker(undefined);
    props.handleCancel();
  }

  return <Modal
    width={"85%"}
    visible={true}
    keyboard={false}
    okText="Guardar"
    onOk={() => {
      form.validateFields()
        .then(handleCreateOrUpdate)
        .then(reset)
        .catch(_ => {})
    }}
    cancelText="Cancelar"
    onCancel={reset}
    confirmLoading={isSaving}
    destroyOnClose
  >
    <Spin tip={isSaving ? "Guardando..." : "Cargando..."} spinning={isLoading || isSaving}>
      {isLoading ? (
        <Skeleton loading />
      ) : (
        <WorkerForm form={form} />
      )}
    </Spin>
  </Modal>
}

export default Workers;
