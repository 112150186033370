class DateTime {
  constructor(datetime) {
    this.datetime = datetime;
  }

  format(pattern = "YY/MM/DD hh:mma") {
    return this.datetime.format(pattern);
  }

  toString() {
    return this.format()
  }
}

class DateAndTime {
  constructor(date, time) {
    this.date = date;
    this.time = time;
  }

  format() {
    return [
      this.date ? this.date.format("YY/MM/DD") : "--/--/--",
      this.time ? this.time.format("hh:mma") : "--:--"
    ].join(" ")
  }

  toString() {
    return this.format()
  }
}

export { DateTime, DateAndTime }
