import React from "react";
import { Form, Input, DatePicker, Space } from "antd";
import { Attachment } from "common/form/attachment";
import { ResourceContext } from "common/form/context";
import { vehiclesApi } from "contractors/api";
import { v4 as uuid } from "uuid";

const ResourceForm = ({ form }) => {
  const resourceId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: vehiclesApi }}>
      <Form
        name="VehicleForm"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          name="id"
          initialValue={resourceId}
          hidden
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label="Tipo de Vehículo"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="make"
          label="Marca"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="identification_code"
          label="Código de Identificación"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Capacidad"
          name="capacity"
        >
          <Input />
        </Form.Item>
        <Attachment label="Ficha Técnica" name="data_sheet" />
        <Form.Item label="Póliza de Seguro">
          <Space align="middle">
            <Attachment btnLabel="Seleccionar..." name="insurance_policy" />
            <Form.Item name="insurance_policy_expires_at">
              <DatePicker placeholder="Expiración" />
            </Form.Item>
          </Space>
        </Form.Item>
      </Form>
    </ResourceContext.Provider>
  );
};

export default ResourceForm;
