import React, { useEffect, useState } from "react";
import { CreateOrUpdate } from "./create_update";
import { Table, Space, Modal, Button, Spin } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { confirm } = Modal;

const Index = ({ columns, dataSourceApi, sortFn, ResourceForm, newLabel, editLabel, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceUpdatedAt, setDataSourceUpdatedAt] = useState(Date.now());
  const [editableRecordId, setEditableRecordId] = useState();
  const [isFormOpen, setIsFormOpen] = React.useState(false);

  useEffect(() => {
    dataSourceApi.index().then((data) => {
      setDataSource(sortFn ? sortFn(data) : data);
      setIsLoading(false);
    })
  }, [dataSourceUpdatedAt]);

  const columnsWithActions = [
    ...columns,
    {
      title: "Acciones",
      key: "actions",
      render: (_text, record) => (
        <Space size="middle">
          <a><EditOutlined onClick={() => startEditMode(record)} /></a>
          {!props.disableDestroy &&
            <a><DeleteOutlined onClick={() => handleDestroy(record)} /></a>}
        </Space>
      ),
    },
  ];

  function startEditMode(record) {
    setEditableRecordId(record.id);
    setIsFormOpen(true);
  };

  function handleDestroy(record) {
    confirm({
      title: `Deseas eliminar el registro?`,
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      okText: "Eliminar",
      cancelText: "Cancelar",
      transitionName: "",
      maskTransitionName: "",
      onOk() {
        dataSourceApi.destroy(record.id).then(() => {
          setDataSource(dataSource.filter(item => item.id !== record.id))
        });
      }
    });
  };

  return (
    <Spin tip={"Cargando..."} spinning={isLoading}>
      <Table dataSource={dataSource} columns={columnsWithActions} rowKey="id" {...props.tableProps} />
      {!props.disableNew &&
        <Button type="primary" onClick={() => setIsFormOpen(true)}>
          {newLabel || "Agregar"}
        </Button>}
      {isFormOpen &&
        <CreateOrUpdate
          newLabel={newLabel}
          editLabel={editLabel}

          modalWidth={props.modalWidth}
          ResourceForm={ResourceForm}
          dataSourceApi={dataSourceApi}
          id={editableRecordId}

          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />}
    </Spin>
  );

  function handleCreate() {
    setIsFormOpen(false);
    setIsLoading(true);
    setDataSourceUpdatedAt(Date.now());
  }

  function handleUpdate() {
    setIsFormOpen(false);
    setIsLoading(true);
    setEditableRecordId(undefined);
    setDataSourceUpdatedAt(Date.now());
  }

  function handleCancel() {
    setIsFormOpen(false);
    setEditableRecordId(undefined);
  }
};

export { Index };
