import React, { useEffect, useState } from "react";
import { Modal, Form, Spin, Skeleton, Alert } from "antd";

const CreateOrUpdate = ({ ResourceForm, dataSourceApi, id, ...props }) => {
  const [form] = Form.useForm();
  const [editableRecord, setEditableRecord] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (id) {
      dataSourceApi.get(id).then((record) => {
        setEditableRecord(record);
        form.setFieldsValue(record);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    return () => {
      if (id) {
        setEditableRecord(undefined);
      }
      setIsLoading(false);
      setIsSaving(false);
    };
  }, []);

  const handleCreateOrUpdate = (formData) => {
    setIsSaving(true);

    const isUpdate = () => (
      !!editableRecord
    );

    return dataSourceApi.createOrUpdate(formData).then((record) => {
      setIsSaving(false);
      if (isUpdate()) {
        props.handleUpdate(formData);
      } else {
        props.handleCreate({ ...formData, id: record.id });
      }
    });
  };

  const handleSubmitError = (error) => {
    if (error instanceof Error)
      setError(error);

    setIsSaving(false);
  };

  const reset = () => {
    form.resetFields();
    setEditableRecord(undefined);
    props.handleCancel();
  }

  return <Modal
    title={editableRecord ? props.editLabel : props.newLabel}
    width={props.modalWidth || "600px"}
    visible={true}
    keyboard={false}
    okText="Guardar"
    onOk={() => {
      form.validateFields()
        .then(handleCreateOrUpdate)
        .then(reset)
        .catch(handleSubmitError)
    }}
    cancelText="Cancelar"
    onCancel={reset}
    confirmLoading={isSaving}
    destroyOnClose
  >
    <Spin tip={isSaving ? "Guardando..." : "Cargando..."} spinning={isLoading || isSaving}>
      {isLoading ? (
        <Skeleton loading />
      ) : (
        <>
          {error &&
            <Alert description={"Lo sentimos, ha ocurrido un error. Por favor intente nuevamente."} type="error" />}
          <ResourceForm form={form} dataSourceApi={dataSourceApi} />
        </>
      )}
    </Spin>
  </Modal>
}

export { CreateOrUpdate };
