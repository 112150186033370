import React from "react";
import { Form, Input } from "antd";
import { v4 as uuid } from "uuid";

const ContractorForm = ({ form }) => {
  const contractorId = form.getFieldValue("id") || uuid();

  return (
    <Form form={form} autoComplete="off" layout="vertical">
      <Form.Item
        name="id"
        initialValue={contractorId}
        hidden
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="name"
        label="Nombre"
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="business_name"
        label="Razón Social"
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="industry"
        label="Especialidad"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="contact_name"
        label="Responsable"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ type: "email", message: "" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="phone_number"
        label="Teléfono"
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default ContractorForm;
