import React from "react"
import { orderBy } from "lodash";
import { crud } from "contractors/api";
import { Index } from "common/crud/index"
import { DateTime } from "common/datetime";
import Form from "./form";

const columns = [
  {
    title: "Proyecto",
    dataIndex: "project_name",
    key: "project_name"
  },
  {
    title: "Compañía/Departamento",
    dataIndex: "company_or_department",
    key: "company_or_departmen"
  },
  {
    title: "Fecha de Elaboración",
    key: "created_at",
    dataIndex: "created_at",
    render: (_, { created_at }) => (
      new DateTime(created_at).format()
    )
  }
];

export { columns };

export default () => (
  <Index
    columns={columns}
    modalWidth="85%"
    dataSourceApi={crud("permissions")}
    ResourceForm={Form}
    newLabel="Nueva Solicitud de Permiso"
    editLabel="Editar Solicitud de Permiso"
    sortFn={(data) => orderBy(data, "created_at", "desc")}
  />
)
