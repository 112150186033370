import React, { useState } from "react";
import {
  Alert, Form, Input, Space, Divider,
  InputNumber, Row, Col, DatePicker, TimePicker, Button,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox } from "common/form/input";
import { ResourceContext } from "common/form/context";
import { v4 as uuid } from "uuid";

const ResourceForm = ({ dataSourceApi, form }) => {
  const resourceId = form.getFieldValue("id") || uuid();
  const { is_approved, comments } = form.getFieldValue("feedback") || {};

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: dataSourceApi }}>
      {is_approved != undefined && (is_approved ? (
        <Alert message="Aprobado" type="success" />
      ) : (
        <Alert
          message="Denegado"
          description={`Comentarios: ${comments}`}
          type="error"
        />
      ))}

      <Form
        name="WorkNotificationForm"
        labelCol={{ span: 8 }}
        form={form}
        autoComplete="off"
      >
        <Divider orientation="left">Datos Generales</Divider>
        <Form.Item
          name="project_name"
          label="Proyecto"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="id"
          label="Folio"
          initialValue={resourceId}
        >
          <Input readOnly />
        </Form.Item>
        <Form.Item
          name="requested_by"
          label="Solicitante"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="company_or_department"
          label="Compañía / Departamento"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="work_area"
          label="Área de Trabajo"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="supervisor"
          label="Supervisor de Seguridad"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="execution_expected_at__date"
          label="Fecha Estimada"
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="execution_expected_at__time"
          label="Hora Estimada"
        >
          <TimePicker format="h:mma" minuteStep={15} use12Hours />
        </Form.Item>
        <Form.Item
          label="No. Trabajadores"
          name="num_of_workers_expected"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descripción"
        >
          <Input.TextArea rows={5} placeholder={
            "Describe la actividad a realizar lo más específico posible (esto le ayudará al supervisor EHS a realizar una mejor " +
            "revisión del área y retroalimentar las medidas de seguridad necesarias, evitándoles retrasos en la actividad)"
          }
          />
        </Form.Item>

        <Divider orientation="left">Maquinaria y Equipo de Poder</Divider>
        <MultipleEquipmentInput />

        <Divider orientation="left">Permisos</Divider>
        <Row>
          <Col span={8}>
            <Space direction="vertical" align="baseline">
              <Checkbox name="General" id="general" />
              <Checkbox name="Chispa y Flama" id="spark_and_flame" />
              <Checkbox name="Trabajos en Alturas" id="heights" />
              <Checkbox name="Maniobra de Riesgo con SQP" id="sqp_maneuver" />
              <Checkbox name="Autorización LOTOTO" id="lototo" />
            </Space>
          </Col>
          <Col span={8}>
            <Space direction="vertical" align="baseline">
              <Checkbox name="Excavación" id="excavation" />
              <Checkbox name="Maquinaria Pesada" id="heavy_machinery" />
              <Checkbox name="Espacios Confinados" id="confined_spaces" />
              <Checkbox name="Manejo de Cargas Suspendidas" id="suspended_loads" />
            </Space>
          </Col>
          <Col span={8}>
            <Space direction="vertical" align="baseline">
              <Checkbox name="Obra&nbsp;Civil" id="construction" />
              <Checkbox name="Trabajos Eléctricos" id="electrical" />
              <Checkbox name="Temperaturas Extremas" id="extreme_temperatures" />
              <Checkbox name="Intervención de Tubería o Ducto" id="pipe_or_duct_intervention" />
            </Space>
          </Col>
        </Row>
      </Form>
    </ResourceContext.Provider>
  );
};

const MultipleEquipmentInput = (props) => (
  <Form.List
    {...props}
    name="equipment"
  >
    {(fields, { add, remove }, { errors }) => (
      <>
        {fields.map((field) => (
          <Space key={field.key} align="baseline">
            <Form.Item
              {...field}
              name={[field.name, 'name']}
              fieldKey={[field.fieldKey, 'name']}
              rules={[{ required: true, message: '' }]}
              style={{ width: "100%" }}
            >
              <Input placeholder="Nombre o descripción" />
            </Form.Item>
            <Form.Item
              {...field}
              name={[field.name, 'qty']}
              fieldKey={[field.fieldKey, 'qty']}
              rules={[{ required: true, message: '' }]}
              initialValue={1}
            >
              <InputNumber />
            </Form.Item>
            <MinusCircleOutlined
              onClick={() => remove(field.name)}
            />
          </Space>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => add()}
            style={{ width: '60%' }}
            icon={<PlusOutlined />}
          >
            Agregar Maquinaria
          </Button>
          <Form.ErrorList errors={errors} />
        </Form.Item>
      </>
    )}
  </Form.List>
)

export default ResourceForm;
