import React from "react";
import { Space, Form, Input, Divider, Radio, List, Descriptions, Table, Typography } from "antd";
import { DateTime, DateAndTime } from "common/datetime";
import { ResourceContext } from "common/form/context";
import { v4 as uuid } from "uuid";

const ResourceForm = ({ dataSourceApi, form }) => {
  const resourceId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: dataSourceApi }}>
      <Summary form={form} />

      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="id"
          initialValue={resourceId}
          hidden
        >
          <Input />
        </Form.Item>

        <Divider orientation="center">Validación</Divider>
        <Feedback.Approval />
        <Feedback.Comments />
      </Form>
    </ResourceContext.Provider>
  );
};

const Feedback = {
  Comments: (props) => (
    <Form.Item
      {...props}
      name={["feedback", "comments"]}
      key={["feedback", "comments"]}
      label="Comentarios"
      wrapperCol={{ span: 8 }}
    >
      <Input.TextArea rows={4} />
    </Form.Item>
  ),
  Approval: (props) => (
    <Form.Item
      {...props}
      name={["feedback", "is_approved"]}
      key={["feedback", "is_approved"]}
      rules={[{ required: true, message: "" }]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        options={[
          { label: "Aprobado", value: true },
          { label: "Denegado", value: false },
        ]}
      />
    </Form.Item>
  )
};

const Summary = ({ form }) => (
  <>
    <Divider orientation="left">Datos Generales</Divider>
    <Descriptions bordered column={1}>
      <Descriptions.Item label="Proyecto">{form.getFieldValue("project_name")}</Descriptions.Item>
      <Descriptions.Item label="Folio">{form.getFieldValue("id")}</Descriptions.Item>
      <Descriptions.Item label="Solicitante">{form.getFieldValue("requested_by")}</Descriptions.Item>
      <Descriptions.Item label="Compañía / Departamento">{form.getFieldValue("company_or_department")}</Descriptions.Item>
      <Descriptions.Item label="Area de Trabajo">{form.getFieldValue("work_area")}</Descriptions.Item>
      <Descriptions.Item label="Responsable Interno">{form.getFieldValue("supervisor")}</Descriptions.Item>
      <Descriptions.Item label="Fecha de Elaboración">
        {new DateTime(form.getFieldValue("created_at")).format()}
      </Descriptions.Item>
      <Descriptions.Item label="Fecha Estimada">
        {new DateAndTime(
          form.getFieldValue("execution_expected_at__date"),
          form.getFieldValue("execution_expected_at__time")
        ).format()}
      </Descriptions.Item>
      <Descriptions.Item label="Duración Estimada">{form.getFieldValue("execution_expected_duration")}</Descriptions.Item>
      <Descriptions.Item label="Descripción">{form.getFieldValue("description")}</Descriptions.Item>
    </Descriptions>
  </>
)

export default ResourceForm;
