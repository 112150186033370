import React from "react";
import { v4 as uuid } from "uuid";
import { Form, Input, InputNumber, Checkbox, Row, Col, Divider, Space } from "antd";
import { chemicalSubstancesApi } from "contractors/api"
import { Attachment } from "common/form/attachment";
import { ResourceContext } from "common/form/context";

const ChemicalSubstanceForm = ({ form }) => {
  const chemicalSubstanceId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: chemicalSubstanceId, api: chemicalSubstancesApi }}>
      <Form
        name="ChemicalSubstanceForm"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onValuesChange={updateTotal}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          name="id"
          initialValue={chemicalSubstanceId}
          hidden
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="name"
          label="Nombre"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="containers_qty"
          label="No. Contenedores"
          rules={[{ type: "number" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="container_cap"
          label="Capacidad c/u"
          rules={[{ type: "number" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Total"
          name="total"
        >
          <InputNumber readOnly />
        </Form.Item>
        <Form.Item
          label="Unidad"
          name="container_cap_unit"
        >
          <Input />
        </Form.Item>
        <Attachment label="Hoja de seguridad" name="security_sheet" />

        <Divider orientation="left">Características</Divider>
        <Row>
          <Col span={12}>
            <Pictogram id="is_flamable" label="Inflamable" />
            <Pictogram id="is_pressured_gas" label="Gas a Presión" />
            <Pictogram id="is_toxic" label="Tóxico" />
            <Pictogram id="is_corrosive" label="Corrosivo" />
            <Pictogram id="is_explosive" label="Explosivo" />
          </Col>
          <Col span={12}>
            <Pictogram id="is_oxidizer" label="Comburente" />
            <Pictogram id="is_cmr" label="CMR" />
            <Pictogram id="is_unhealthy" label="Nocivo a la Salud" />
            <Pictogram id="is_toxic_for_env" label="Tóxico Ambiental" />
          </Col>
        </Row>
      </Form>
    </ResourceContext.Provider>
  );

  function updateTotal(_, values) {
    form.setFieldsValue({
      total: (values.containers_qty == undefined || values.container_cap == undefined) ? (
        undefined
      ) : (
        values.containers_qty * values.container_cap
      )
    })
  }
};

const Pictogram = ({ id, label, size=64 }) => (
  <Form.Item
    name={id}
    valuePropName="checked"
  >
    <Checkbox>
      <Space>
        <img src={require(`./pictograms/${id}.png`)} width={size} height={size} />
        {label}
      </Space>
    </Checkbox>
  </Form.Item>
)

export default ChemicalSubstanceForm;
