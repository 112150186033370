import React from "react";
import { orderBy } from "lodash";
import { toolsApi } from "contractors/api";
import { Index } from "common/crud/index"
import ToolForm from "./form"
import { Typography } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
const { Text } = Typography;

const columns = [
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Marca",
    key: "brand",
    dataIndex: "brand",
  },
  {
    title: "Código de Identificación",
    dataIndex: "identification_code",
    key: "identification_code"
  },
  {
    title: "Color",
    dataIndex: "color",
    key: "color"
  },
  {
    title: "Capacidad",
    dataIndex: "capacity",
    key: "capacity"
  },
  {
    title: "Ficha Técnica",
    dataIndex: "data_sheet",
    key: "data_sheet",
    render: (_text, record) => {
      const [dataSheet] = record.attachments.data_sheet || [];
      return dataSheet && <a href={dataSheet.url} target="_blank">
        <FileTextOutlined />
        {" "}
        <Text style={{ width: "80px" }} ellipsis>{dataSheet.name}</Text>
      </a>
    }
  }
];

export default () => (
  <Index
    columns={columns}
    dataSourceApi={toolsApi}
    sortFn={(data) => orderBy(data, "created_at", "desc")}
    newLabel="Nuevo Equipo / Herramienta"
    editLabel="Editar Equipo / Herramienta"
    ResourceForm={ToolForm}
  />
)
