import moment from "moment";
import { getFileList } from "common/form/attachment";

const getContractorRequirements = async () => (
  fetch("/api/admins/contractor_requirements", {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(response => response.json())
)

const saveContractorRequirements = async (formData) => (
  fetch("/api/admins/contractor_requirements", {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf()
    },
    body: JSON.stringify(formData)
  })
    .then(response => response.json())
)

const getContractors = async () => (
  fetch("/api/admins/contractors", {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(response => response.json())
)

const getContractor = async (id) => (
  fetch(`/api/admins/contractors/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
    .then(response => response.json())
)

const destroyContractor = async (id) => (
  fetch(`/api/admins/contractors/${id}`, {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": csrf(),
    },
  }).then((response) => response)
)

const createOrUpdateContractor = async ({ id, ...formData }) => (
  fetch(`/api/admins/contractors/${id}`, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      "X-CSRF-Token": csrf(),
    },
    body: JSON.stringify(formData)
  }).then((response) => response.json())
)

const crud = (resource) => ({
  index: async () => (
    fetch(`/api/admins/${resource}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(resources => resources.map(format))
  ),

  get: async (id) => (
    fetch(`/api/admins/${resource}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(format)
  ),

  destroy: async (id) => (
    fetch(`/api/admins/${resource}/${id}`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrf(),
      },
    }).then((response) => {
      if (response.ok) {
        return response;
      } else {
        throw new Error(`Unable to destroy ${resource} id=${id}`);
      }
    })
  ),

  createOrUpdate: async ({ id, ...formData }) => (
    fetch(`/api/admins/${resource}/${id}`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        "X-CSRF-Token": csrf(),
      },
      body: JSON.stringify(formData)
    }).then((response) => response.json())
  ),

  attachments: {
    createOrUpdateUrl: ({ resource_id, attachment_id }) => (
      `/api/admins/${resource}/${resource_id}/attachments/${attachment_id}`
    ),

    destroy: async ({ attachment_id, resource_id, response }) => {
      if (response) {
        resource_id = response.resource_id;
      }
      return fetch(`/api/admins/${resource}/${resource_id}/attachments/${attachment_id}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrf()
        }
      })
    }
  }
});

const csrf = () => (
  document.querySelector("meta[name='csrf-token']")
    .getAttribute("content")
)

const format = (resource) => {
  for (const [key, value] of Object.entries(resource)) {
    if (!value) continue
    if (isDateTime(key)) {
      resource[key] = moment(value)
    }
  }

  if (resource.hasOwnProperty("attachments")) {
    resource.attachments = [...resource.attachments].reduce((obj, attachment) => {
      obj[attachment.id] = getFileList(resource.id, attachment);
      return obj;
    }, {});
  }

  return resource;
}

const isDateTime = (key) => (
  key.slice(-3) === "_at" || key.slice(-6) === "__date" || key.slice(-6) === "__time"
)

export {
  crud,
  getContractorRequirements, saveContractorRequirements,
  getContractors, getContractor, createOrUpdateContractor, destroyContractor,
  csrf
}
