import React from "react";
import { TeamOutlined, PartitionOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import { Menu } from "antd";
const { SubMenu: AntSubMenu } = Menu;

const SubMenuImpl = {
  Access: (props) => (
    <SubMenu
      {...props}
      title={`Acceso de Personal`}
      icon={<TeamOutlined />}
      routes={["Inducción General", "Inducción Supervisores", "Credenciales"]}
    />
  ),
  Activities: (props) => (
    <SubMenu
      {...props}
      title={"Actividades de Control"}
      icon={<PartitionOutlined />}
      routes={[
        "Recorridos de Supervisión",
        "Actos y Condiciones Inseguras",
        "Investigación de Accidentes",
        "Alertas EHS",
        "Reportes de Incumplimiento",
        "Entrevista de Cultura Laboral",
        "Pláticas de 5 minutos"
      ]}
    />
  ),
  Misc: (props) => (
    <SubMenu
      {...props}
      title={"Actividades Complementarias"}
      icon={<AppstoreAddOutlined />}
      routes={[
        "Fuerza de Trabajo",
        "Gestión de Acuerdos",
        "Avance de Actividades",
        "Proyectos de Mejora EHS",
        "Informes de Resultados",
        "Comunicación y Documentos",
        "Restricción/Liberación Personal EHS"
      ]}
    />
  )
}

const SubMenu = ({ title, icon, routes, ...props }) => (
  <AntSubMenu {...props} title={title} icon={icon}>
    {routes.map((route, i) => (
      <Menu.Item key={`${props.eventKey}_${i}`}>
        {route}
      </Menu.Item>
    ))}
  </AntSubMenu>
);

export { SubMenuImpl as SubMenu }
