import React from "react"
import { orderBy } from "lodash";
import { crud } from "admins/api";
import { Index } from "common/crud/index";
import { columns } from "contractors/components/risk_management/security_analyses/index";
import Form from "./form"

export default () => (
  <Index
    columns={columns}
    dataSourceApi={crud("security_analyses")}
    sortFn={(data) => orderBy(data, [["feedback", "is_approved"], "created_at"], ["desc", "asc"])}
    ResourceForm={Form}
    modalWidth="85%"
    editLabel="Análisis de Seguridad"
    disableNew
    disableDestroy
  />
)
