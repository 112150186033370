import React from "react"
import { orderBy } from "lodash";
import { crud } from "admins/api";
import { Index } from "common/crud/index"
import { DateTime, DateAndTime } from "common/datetime";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import WorkNotificationForm from "./form"

const columns = [
  {
    title: "Proyecto",
    dataIndex: "project_name",
    key: "project_name"
  },
  {
    title: "No. Trabajadores",
    dataIndex: "num_of_workers_expected",
    key: "num_of_workers_expected"
  },
  {
    title: "Fecha de Elaboración",
    key: "created_at",
    dataIndex: "created_at",
    render: (_, { created_at }) => (
      (new DateTime(created_at)).format()
    )
  },
  {
    title: "Fecha de Ejecución",
    key: "execution_expected_at",
    render: (_, { execution_expected_at__date: date, execution_expected_at__time: time }) => (
      (new DateAndTime(date, time)).format()
    )
  },
  {
    title: "Estado",
    key: "status",
    render: (_, { feedback: { is_approved } = {} }) => {
      if (is_approved == undefined || is_approved == null)
        return "-"

      return is_approved ? (
        <CheckCircleOutlined />
      ) : (
        <ExclamationCircleOutlined />
      );
    }
  },
];

export default () => (
  <Index
    columns={columns}
    dataSourceApi={crud("work_notifications")}
    sortFn={(data) => orderBy(data, [["feedback", "is_approved"], "created_at"], ["desc", "asc"])}
    ResourceForm={WorkNotificationForm}
    modalWidth="720px"
    editLabel="Aviso de Trabajo"
    disableNew
    disableDestroy
  />
)
