import React from "react";
import { ReconciliationOutlined } from "@ant-design/icons";
import { SubMenu, Routes } from "common/layout/menu";
import WorkNotifications from "admins/components/risk_management/work_notifications";
import SecurityAnalyses from "admins/components/risk_management/security_analyses";
import EquipmentValidation from "admins/components/risk_management/equipment_validation";
import Permissions from "admins/components/risk_management/permissions";

const basePath = "/gestion-de-riesgo";
const routes = [
  {
    name: "Avisos de Trabajo",
    path: "/",
    component: WorkNotifications
  },
  {
    name: "Análisis de Seguridad",
    path: "/analisis-de-seguridad",
    component: SecurityAnalyses
  },
  {
    name: "Revisión de Equipos",
    path: "/revision-de-equipos",
    component: EquipmentValidation
  },
  {
    name: "Permisos de Trabajo de Riesgo",
    path: "/permisos",
    component: Permissions
  }
]

const SubMenuImpl = (props) => (
  <SubMenu
    {...props}
    title={`Gestión de Riesgo`}
    icon={<ReconciliationOutlined />}
    basePath={basePath}
    routes={routes}
  />
)

const RoutesImpl = () => (
  <Routes basePath={basePath} routes={routes} />
)

export { SubMenuImpl as SubMenu, RoutesImpl as Routes }
