import React, { useEffect, useState } from "react"
import { Button, Upload, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { csrf, getRequirements, destroyFile } from "contractors/api"

const RequirementsForm = () => {
  const [requirements, setRequirements] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getRequirements();
      setRequirements(response.requirements);
    })()
  }, []);

  return (
    <div className="ant-table">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <table id="RequirementsForm" style={{tableLayout: "auto"}}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" width="40%">
                  Requerimiento
                </th>
                <th className="ant-table-cell" width="20%">
                  Referencia
                </th>
                <th className="ant-table-cell">
                  Instrucciones
                </th>
              </tr>
            </thead>
            {requirements.map((requirement, key) => (
              <tbody key={key} className="ant-table-tbody">
                <tr className="ant-table-row ant-table-row-level-0">
                  <td className="ant-table-cell">
                    <UploadRequirement {...requirement} />
                  </td>
                  <td className="ant-table-cell">
                    {requirement.reference}
                  </td>
                  <td className="ant-table-cell">
                    {requirement.instructions}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </div>
  )
}

const UploadRequirement = (requirement) => {
  const getFileList = requirement => (
    requirement.blob ? [{
      requirement_id: requirement.id,
      blob_id: requirement.blob.id,
      uid: requirement.blob.id,
      url: requirement.blob.url,
      name: requirement.blob.filename,
      status: "done"
    }] : []
  );

  const [fileList, setFileList] = useState(getFileList(requirement));

  const updateFileList = (info) => {
    let fileList = [...info.fileList];

    fileList = fileList.map(file => {
      if (file.response) {
        file.requirement_id = requirement.id;
        file.blob_id = file.response.blob.id;
        file.url = file.response.blob.url;
      }
      return file;
    });

    setFileList(fileList);
  }

  return <Upload maxCount={1}
    data={{ id: requirement.id }}
    fileList={fileList}
    listType="text"
    onChange={updateFileList}
    onRemove={destroyFile}
    action="/api/contractors/requirements"
    method="PUT"
    headers={{ "X-CSRF-Token": csrf() }}
  >
    <Space>
      <Button icon={<UploadOutlined />} />
      {requirement.name}
    </Space>
  </Upload>
}

export default RequirementsForm
