import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ConfigProvider, Layout, Menu } from "antd";
import { Contractors, RiskManagement } from "admins/spa/menu";
import { Mock } from "contractors/spa/menu";
import ErrorBoundary from "common/error_boundary";
import es from 'antd/lib/locale/es_ES';
const { Header, Content, Sider } = Layout;

const App = () => {
  const path = window.location.pathname.split("/");
  const defaultOpenKey = path[2] || "contratistas";
  const defaultMenuKey = ["", defaultOpenKey, (path[3] || "")].join("/");

  return <ConfigProvider locale={es}>
    <Router basename="/admins">
      <Layout style={{ minHeight: "100vh" }}>
        <Header className="header">
          <div className="logo contractor">
            <span>Admin</span>
          </div>
        </Header>
        <Layout>
          <Sider width={300} className="site-layout-background">
            <Menu
              mode="inline"
              defaultOpenKeys={[defaultOpenKey]}
              defaultSelectedKeys={[defaultMenuKey]}
              style={{ height: '100%', borderRight: 0 }}
            >
              <Contractors.SubMenu key={"contratistas"} />
              <Mock.SubMenu.Access key={"acceso-de-personal"} />
              <RiskManagement.SubMenu key={"gestion-de-riesgo"} />
              <Mock.SubMenu.Activities key={"actividades-de-control"} />
              <Mock.SubMenu.Misc key={"actividades-complementarias"} />
            </Menu>
          </Sider>
          <Layout style={{ padding: '24px' }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <ErrorBoundary>
                <Contractors.Routes />
                <RiskManagement.Routes />
              </ErrorBoundary>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  </ConfigProvider>;
}

export default App
