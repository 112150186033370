import React from "react";
import { Form, Input, Divider, Radio, List, Descriptions, Table } from "antd";
import { DateTime, DateAndTime } from "common/datetime";
import { ResourceContext } from "common/form/context";
import { v4 as uuid } from "uuid";

const ResourceForm = ({ dataSourceApi, form }) => {
  const resourceId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: dataSourceApi }}>
      <Summary form={form} />

      <Form
        name="WorkNotificationForm"
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="id"
          initialValue={resourceId}
          hidden
        >
          <Input />
        </Form.Item>

        <Divider orientation="center">Revisión de Área de Trabajo</Divider>
        <Feedback.Question label="Se acudió al área y se realizó recorrido para la identificación de riesgos" />
        <Feedback.Question label="Se pudo revisar el equipo a utilizar en el momento y está en buenas condiciones" />
        <Feedback.Question label="Existen condiciones adecuadas o ajustables para la ejecución de los trabajos mencionados" />
        <Feedback.Question label="El área de trabajo estará libre para realizar las actividades mencionadas" />
        <Feedback.ActionItems />
        <Feedback.Approval />
      </Form>
    </ResourceContext.Provider>
  );
};

const Feedback = {
  Question: (props) => (
    <Form.Item
      {...props}
      name={["feedback", props.label]}
      key={["feedback", props.label]}
      rules={[{ required: true, message: "" }]}
    >
      <Radio.Group>
        <Radio value="true">Sí</Radio>
        <Radio value="false">No</Radio>
        <Radio value="N/A">N/A</Radio>
      </Radio.Group>
    </Form.Item>
  ),
  Approval: (props) => (
    <Form.Item
      {...props}
      name={["feedback", "is_approved"]}
      key={["feedback", "is_approved"]}
      rules={[{ required: true, message: "" }]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        options={[
          { label: "Aprobado", value: true },
          { label: "Denegado", value: false },
        ]}
      />
    </Form.Item>
  ),
  ActionItems: (props) => (
    <Form.Item
      {...props}
      name={["feedback", "comments"]}
      key={["feedback", "comments"]}
      label="Para poder realizar la actividad se requiere hacer los ajustes o instalaciones siguientes:"
      rules={[{ required: true, message: "" }]}
    >
      <Input.TextArea rows={5} />
    </Form.Item>
  )
};

const Summary = ({ form }) => (
  <>
    <Divider orientation="left">Datos Generales</Divider>
    <Descriptions bordered column={1}>
      <Descriptions.Item label="Proyecto">{form.getFieldValue("project_name")}</Descriptions.Item>
      <Descriptions.Item label="Folio">{form.getFieldValue("id")}</Descriptions.Item>
      <Descriptions.Item label="Solicitante">{form.getFieldValue("requested_by")}</Descriptions.Item>
      <Descriptions.Item label="Compañía / Departamento">{form.getFieldValue("company_or_department")}</Descriptions.Item>
      <Descriptions.Item label="Area de Trabajo">{form.getFieldValue("work_area")}</Descriptions.Item>
      <Descriptions.Item label="Supervisor de Seguridad">{form.getFieldValue("supervisor")}</Descriptions.Item>
      <Descriptions.Item label="Fecha de Elaboración">
        {new DateTime(form.getFieldValue("created_at")).format()}
      </Descriptions.Item>
      <Descriptions.Item label="Fecha Estimada">
        {new DateAndTime(
          form.getFieldValue("execution_expected_at__date"),
          form.getFieldValue("execution_expected_at__time")
        ).format()}
      </Descriptions.Item>
      <Descriptions.Item label="No. Trabajadores">{form.getFieldValue("num_of_workers_expected")}</Descriptions.Item>
      <Descriptions.Item label="Descripción">{form.getFieldValue("description")}</Descriptions.Item>
    </Descriptions>

    <Divider orientation="left">Maquinaria y Equipo de Poder</Divider>
    <Table
      bordered
      pagination={false}
      columns={[
        { title: "Nombre", dataIndex: "name", key: "name" },
        { title: "Cant.", dataIndex: "qty", key: "qty" }
      ]}
      dataSource={
        (form.getFieldValue("equipment") || [])
          .map((item, i) => { item.key = i; return item })
      }
    />

    <Divider orientation="left">Permisos</Divider>
    <List
      bordered
      dataSource={getPermissions(form)}
      renderItem={item => <List.Item>{item.name}</List.Item>}
    />
  </>
)

const getPermissions = (form) => {
  const permissions = [
    { name: "Autorización LOTOTO", id: "lototo" },
    { name: "Chispa y Flama", id: "spark_and_flame" },
    { name: "Espacios Confinados", id: "confined_spaces" },
    { name: "Excavación", id: "excavation" },
    { name: "General", id: "general" },
    { name: "Intervención de Tubería o Ducto", id: "pipe_or_duct_intervention" },
    { name: "Manejo de Cargas Suspendidas", id: "suspended_loads" },
    { name: "Maniobra de Riesgo con SQP", id: "sqp_maneuver" },
    { name: "Maquinaria Pesada", id: "heavy_machinery" },
    { name: "Obra Civil", id: "construction" },
    { name: "Temperaturas Extremas", id: "extreme_temperatures" },
    { name: "Trabajos Eléctricos", id: "electrical" },
    { name: "Trabajos en Alturas", id: "heights" },
  ];

  return permissions.map(permission => {
    permission.isChecked = form.getFieldValue(permission.id);
    return permission;
  }).filter(p => p.isChecked);
}

export default ResourceForm;
