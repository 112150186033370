import React from "react";
import { Space, Form, Input, Divider, Button, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Attachment } from "common/form/attachment";
import { ResourceContext } from "common/form/context";
import { v4 as uuid } from "uuid";
const { Option } = Select;

const ResourceForm = ({ dataSourceApi, form }) => {
  const resourceId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: dataSourceApi }}>
      <Form
        name="EquipmentValidationForm"
        labelCol={{ span: 8 }}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          name="id"
          initialValue={resourceId}
          hidden
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="equipment_type"
          label="Tipo"
          initialValue="tools"
        >
          <Select>
            <Option value="tools">Maquinaria y Equipo</Option>
            <Option value="epp">Equipo de Protección Personal</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Nombre"
        >
          <Input />
        </Form.Item>
        <Attachment label="Imagen" name="image" />

        <Divider orientation="left">Checklist</Divider>
        <ChecklistInput />
      </Form>
    </ResourceContext.Provider>
  );
};


const ChecklistInput = (props) => (
  <Form.List
    {...props}
    name="checklist"
  >
    {(fields, { add, remove }, { errors }) => (
      <>
        {fields.map((field) => (
          <Space key={field.key} align="baseline">
            <Form.Item
              {...field}
              name={[field.name, 'description']}
              fieldKey={[field.fieldKey, 'description']}
              rules={[{ required: true, message: '' }]}
              style={{ width: "300px" }}
            >
              <Input placeholder="Descripción" />
            </Form.Item>
            <MinusCircleOutlined
              onClick={() => remove(field.name)}
            />
          </Space>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => add()}
            style={{ width: '60%' }}
            icon={<PlusOutlined />}
          >
            Agregar Revisión
          </Button>
          <Form.ErrorList errors={errors} />
        </Form.Item>
      </>
    )}
  </Form.List>
)


export default ResourceForm;
