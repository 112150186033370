import React, { useEffect, useState } from 'react'
import { Form, Input, Checkbox, Button, Row, Col, Spin, Alert } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getContractorRequirements, saveContractorRequirements } from 'admins/api'
import { v4 as uuid } from 'uuid'

const ContractorRequirementsForm = () => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ isSaving: false, isLoading: true });
  const { isLoading, isSaving, hasErrors } = state;

  useEffect(() => {
    (async () => {
      form.setFieldsValue(await getContractorRequirements());
      setState(prev => ({ ...prev, isLoading: false }));
    })()
  }, [state.updatedAt]);

  const save = formData => {
    setState(prev => ({ ...prev, isSaving: true, hasErrors: false }));
    saveContractorRequirements(formData)
      .then(({ updated_at: updatedAt }) => setState({ isSaving: false, updatedAt }))
      .catch(_ => setState(prev => ({ ...prev, isSaving: false, hasErrors: true })))
  }

  return (
    <Spin tip={isSaving ? "Guardando..." : "Cargando..."} spinning={isLoading || isSaving}>
      <Form name="ContractorRequirementsForm"
        form={form}
        onFinish={save}
        autoComplete="off"
      >
        {hasErrors &&
          <Alert type="warning"
            message="Error al guardar, por favor intenta de nuevo"
            showIcon
            closable />
        }
        <Form.List name="requirements">
          {(fields, { add, remove }, { errors }) => (
            <>
              <Row align="middle" gutter={12} style={{ marginBottom: "24px" }}>
                <Col span={8}>
                  <strong>Requerimiento</strong>
                </Col>
                <Col span={4}>
                  <strong>Referencia</strong>
                </Col>
                <Col span={8}>
                  <strong>Instrucciones</strong>
                </Col>
                <Col span={3} align="center">
                  <strong>Requerido</strong>
                </Col>
              </Row>

              {fields.map(({ key, fieldKey, ...field }) => (
                <Row key={key} align="middle" gutter={12} style={{ marginBottom: "24px" }}>
                  <Col span={8}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      fieldKey={[fieldKey, 'name']}
                      rules={[{ required: true, message: '' }]}
                    >
                      <Input placeholder="Nombre" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'reference']}
                      fieldKey={[fieldKey, 'reference']}
                    >
                      <Input placeholder="Referencia" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'instructions']}
                      fieldKey={[fieldKey, 'instructions']}
                    >
                      <Input.TextArea placeholder="Instrucciones" />
                    </Form.Item>
                  </Col>
                  <Col span={3} align="center">
                    <Form.Item
                      {...field}
                      name={[field.name, 'is_required']}
                      fieldKey={[fieldKey, 'is_required']}
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                    </Col>
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add(({ is_required: true, id: uuid() }))}
                  style={{ width: "40%", marginBottom: "12px" }}
                  icon={<PlusOutlined />}
                >
                  Agregar Requerimiento
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ContractorRequirementsForm
