import React from "react";
import { Space, Form, Input, Divider, Radio, List, Descriptions, Table, Typography } from "antd";
import { DateTime, DateAndTime } from "common/datetime";
import { ResourceContext } from "common/form/context";
import { v4 as uuid } from "uuid";

const ResourceForm = ({ dataSourceApi, form }) => {
  const resourceId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: dataSourceApi }}>
      <Summary form={form} />

      <Form
        name="WorkNotificationForm"
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="id"
          initialValue={resourceId}
          hidden
        >
          <Input />
        </Form.Item>

        <Divider orientation="center">Validación</Divider>
        <Feedback.Approval />
        <Feedback.Comments />
      </Form>
    </ResourceContext.Provider>
  );
};

const Feedback = {
  Comments: (props) => (
    <Form.Item
      {...props}
      name={["feedback", "comments"]}
      key={["feedback", "comments"]}
      label="Comentarios"
      wrapperCol={{ span: 8 }}
    >
      <Input.TextArea rows={4} />
    </Form.Item>
  ),
  Approval: (props) => (
    <Form.Item
      {...props}
      name={["feedback", "is_approved"]}
      key={["feedback", "is_approved"]}
      rules={[{ required: true, message: "" }]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        options={[
          { label: "Aprobado", value: true },
          { label: "Denegado", value: false },
        ]}
      />
    </Form.Item>
  )
};

const Summary = ({ form }) => (
  <>
    <Divider orientation="left">Datos Generales</Divider>
    <Descriptions bordered column={1}>
      <Descriptions.Item label="Proyecto">{form.getFieldValue("project_name")}</Descriptions.Item>
      <Descriptions.Item label="Folio">{form.getFieldValue("id")}</Descriptions.Item>
      <Descriptions.Item label="Solicitante">{form.getFieldValue("requested_by")}</Descriptions.Item>
      <Descriptions.Item label="Compañía / Departamento">{form.getFieldValue("company_or_department")}</Descriptions.Item>
      <Descriptions.Item label="Area de Trabajo">{form.getFieldValue("work_area")}</Descriptions.Item>
      <Descriptions.Item label="Responsable Interno">{form.getFieldValue("supervisor")}</Descriptions.Item>
      <Descriptions.Item label="Fecha de Elaboración">
        {new DateTime(form.getFieldValue("created_at")).format()}
      </Descriptions.Item>
      <Descriptions.Item label="Fecha Estimada">
        {new DateAndTime(
          form.getFieldValue("execution_expected_at__date"),
          form.getFieldValue("execution_expected_at__time")
        ).format()}
      </Descriptions.Item>
      <Descriptions.Item label="Duración Estimada">{form.getFieldValue("execution_expected_duration")}</Descriptions.Item>
      <Descriptions.Item label="Descripción">{form.getFieldValue("description")}</Descriptions.Item>
    </Descriptions>

    <Divider orientation="left">Pre-evaluación de Riesgos y Peligros</Divider>
    <Space direction="horizontal" align="baseline" wrap>
      <Risks label="A. EPP" dataSource={getRisks(form, "epp")} />
      <Risks label="B. Peligros del Área y Trabajo" dataSource={getRisks(form, "dangers")} />
      <Risks label="C. Permiso por Tramitar" dataSource={getRisks(form, "permission")} />
      <Risks label="D. Orden y Limpieza" dataSource={getRisks(form, "cleaning")} />
      <Risks label="E. Capacitaciones Adicionales" dataSource={getRisks(form, "complementary_training")} />
    </Space>

    <Divider orientation="left">Análisis de Seguridad en el Trabajo</Divider>
    <SecurityAnalyses form={form} />
  </>
)

const SecurityAnalyses = ({ form }) => (
  <Table
    bordered
    pagination={false}
    className="truncated"
    dataSource={
      (form.getFieldValue("activities") || [])
        .map((item, i) => { item.key = i; return item })}
    columns={[
      {
        title: "Descripción",
        dataIndex: "description",
        key: "description",
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: 212.5,
            }
          }
        },
        render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>
      },
      {
        title: "Tiempo de Ejecución", dataIndex: "execution_time", key: "execution_time",
      },
      {
        title: "Riesgos",
        dataIndex: "risks",
        key: "risks",
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: 212.5,
            }
          }
        },
        render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>
      },
      { title: "N.P.", dataIndex: "danger_level", key: "danger_level" },
      {
        title: "Controles y Medidas de Seguridad",
        dataIndex: "mitigation",
        key: "mitigation",
        onCell: () => {
          return {
            style: {
              whiteSpace: 'nowrap',
              maxWidth: 212.5,
            }
          }
        },
        render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>
      },
      {
        title: "N.R.A.",
        key: "risk_level",
        render: (_, { execution_time, danger_level }) => {
          if (execution_time == undefined || danger_level == undefined)
            return "-"
          return parseFloat(execution_time * danger_level).toFixed(2);
        }
      },
    ]}
  />
)

const Risks = ({ label, dataSource }) => (
  <List
    bordered
    header={<strong>{label}</strong>}
    dataSource={dataSource}
    renderItem={item => <List.Item><Typography.Text ellipsis>{item}</Typography.Text></List.Item>}
    style={{ minWidth: "170px", maxWidth: "500px"}}
  />
)

const getRisks = (form, category) => {
  let risks = form.getFieldValue(category);
  const other = form.getFieldValue(`${category}_other`);
  if (other) {
    risks = [...risks, `Otro(s): ${other}`];
  }
  return risks;
}

export default ResourceForm;
