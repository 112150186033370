import React, { useState } from "react"
import { orderBy } from "lodash";
import { crud } from "admins/api";
import { Index } from "common/crud/index";
import { columns } from "contractors/components/risk_management/permissions/index";
import { Menu } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Form from "./form"

const adminColumns = [
  ...columns,
  {
    title: "Estado",
    key: "status",
    render: (_, { feedback: { is_approved } = {} }) => {
      if (is_approved == undefined || is_approved == null)
        return "-"
      return is_approved ? (
        <CheckCircleOutlined />
      ) : (
        <ExclamationCircleOutlined />
      );
    }
  },
];

const StatusNavigation = () => {
  const [current, setCurrent] = useState("requests");
  const onClick = e => {
    setCurrent(e.key)
  };

  return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal">
    <Menu.Item key="requests">
      Solicitudes
    </Menu.Item>
    <Menu.Item key="processed">
      Aprobados/Denegados
    </Menu.Item>
    <Menu.Item key="extensions">
      Extensiones
    </Menu.Item>
    <Menu.Item key="closed">
      Cerrados
    </Menu.Item>
  </Menu>
}

export default () => (
  <>
    <StatusNavigation />
    <Index
      columns={adminColumns}
      dataSourceApi={crud("permissions")}
      sortFn={(data) => orderBy(data, [["feedback", "is_approved"], "created_at"], ["desc", "asc"])}
      ResourceForm={Form}
      modalWidth="85%"
      editLabel="Permiso de Trabajo"
      disableNew
      disableDestroy
    />
  </>
)
