import React from "react";
import { FolderOpenOutlined } from "@ant-design/icons";
import { SubMenu, Routes } from "common/layout/menu";
import ContractorRequirementsForm from "admins/components/contractors/contractor_requirements/form";
import Contractors from "admins/components/contractors/contractors/index";

const basePath = "/contratistas";
const routes = [
  {
    name: "Contratistas",
    path: "/",
    component: Contractors
  },
  {
    name: "Lista de Requerimientos",
    path: "/requerimientos",
    component: ContractorRequirementsForm
  }
]

const SubMenuImpl = (props) => (
  <SubMenu
    {...props}
    title={`Expedientes de Contratistas`}
    icon={<FolderOpenOutlined />}
    basePath={basePath}
    routes={routes}
  />
)

const RoutesImpl = () => (
  <Routes basePath={basePath} routes={routes} />
)

export { SubMenuImpl as SubMenu, RoutesImpl as Routes }
