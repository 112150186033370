import React, { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { Table, Space, Typography, Modal, Form, Button, Spin, Skeleton } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileProtectOutlined
} from "@ant-design/icons";
import { chemicalSubstancesApi } from "contractors/api";
import ChemicalSubstanceForm from "./form";
const { Text } = Typography;
const { confirm } = Modal;

const ChemicalSubstances = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceUpdatedAt, setDataSourceUpdatedAt] = useState(Date.now());
  const [editableChemicalSubstanceId, setEditableChemicalSubstanceId] = useState();
  const [createOrUpdateChemicalSubstance, setCreateOrUpdateChemicalSubstance] = React.useState(false);

  useEffect(() => {
    chemicalSubstancesApi.index().then((chemicalSubstances) => {
      setDataSource(orderBy(chemicalSubstances, "created_at", "desc"));
      setIsLoading(false);
    })
  }, [dataSourceUpdatedAt]);

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "No. de Contenedores",
      key: "containers_qty",
      dataIndex: "containers_qty",
    },
    {
      title: "Capacidad del Contenedor",
      dataIndex: "container_cap",
      key: "container_cap"
    },
    {
      title: "Total",
      key: "total",
      render: (_text, record) => (
        (record.containers_qty == undefined || record.container_cap == undefined) ? (
          undefined
        ) : (
          record.containers_qty * record.container_cap
        )
      ),
    },
    {
      title: "Unidad",
      dataIndex: "container_cap_unit",
      key: "container_cap_unit",
      render: (text, _record) => (
        text && text.toLowerCase()
      ),
    },
    {
      title: "Hoja de Seguridad",
      key: "security_sheet",
      render: (_text, record) => {
        const [securitySheet] = record.attachments.security_sheet || [];
        return securitySheet && <a href={securitySheet.url} target="_blank">
            <FileProtectOutlined />
            {" "}
            <Text style={{ width: "80px" }} ellipsis>{securitySheet.name}</Text>
          </a>
      },
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_text, record) => (
        <Space size="middle">
          <a><EditOutlined onClick={() => startEditMode(record)} /></a>
          <a><DeleteOutlined onClick={() => handleDestroy(record)} /></a>
        </Space>
      ),
    },
  ];

  function startEditMode(record) {
    setEditableChemicalSubstanceId(record.id);
    setCreateOrUpdateChemicalSubstance(true);
  };

  function handleDestroy(record) {
    confirm({
      title: `Deseas eliminar la Sustancia?`,
      icon: <ExclamationCircleOutlined />,
      content: `- ${record.name}`,
      okType: "danger",
      okText: "Eliminar",
      cancelText: "Cancelar",
      transitionName: "",
      maskTransitionName: "",
      onOk() {
        chemicalSubstancesApi.destroy(record.id).then(() => {
          setDataSource(dataSource.filter(chemicalSubstance => chemicalSubstance.id !== record.id))
        });
      }
    });
  };

  return (
    <Spin tip={"Cargando..."} spinning={isLoading}>
      <Table dataSource={dataSource} columns={columns} rowKey="id" />
      <Button type="primary" onClick={() => setCreateOrUpdateChemicalSubstance(true)}>
        Nueva Sustancia Química
      </Button>
      {createOrUpdateChemicalSubstance &&
        <CreateOrUpdateChemicalSubstance
          id={editableChemicalSubstanceId}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />}
    </Spin>
  );

  function handleCreate() {
    setIsLoading(true);
    setDataSourceUpdatedAt(Date.now());
  }

  function handleUpdate() {
    setIsLoading(true);
    setEditableChemicalSubstanceId(undefined);
    setDataSourceUpdatedAt(Date.now());
  }

  function handleCancel() {
    setCreateOrUpdateChemicalSubstance(false);
    setEditableChemicalSubstanceId(undefined);
  }
};

const CreateOrUpdateChemicalSubstance = ({ id, ...props }) => {
  const [form] = Form.useForm();
  const [editableChemicalSubstance, setEditableChemicalSubstance] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (id) {
      chemicalSubstancesApi.get(id).then((chemicalSubstance) => {
        setEditableChemicalSubstance(chemicalSubstance);
        form.setFieldsValue(chemicalSubstance);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    return () => {
      if (id) {
        setEditableChemicalSubstance(undefined);
      }
      setIsLoading(false);
      setIsSaving(false);
    };
  }, []);

  const handleCreateOrUpdate = (formData) => {
    setIsSaving(true);

    const isUpdate = () => (
      !!editableChemicalSubstance
    );

    return chemicalSubstancesApi.createOrUpdate(formData).then((chemicalSubstance) => {
      setIsSaving(false);
      if (isUpdate()) {
        props.handleUpdate(formData);
      } else {
        props.handleCreate({ ...formData, id: chemicalSubstance.id });
      }
    });
  };

  const reset = () => {
    form.resetFields();
    setEditableChemicalSubstance(undefined);
    props.handleCancel();
  }

  return <Modal
    title={`${editableChemicalSubstance ? "Editar" : "Nueva"} Sustancia`}
    width="600px"
    visible={true}
    keyboard={false}
    okText="Guardar"
    onOk={() => {
      form.validateFields()
        .then(handleCreateOrUpdate)
        .then(reset)
        .catch(_ => {})
    }}
    cancelText="Cancelar"
    onCancel={reset}
    confirmLoading={isSaving}
    destroyOnClose
  >
    <Spin tip={isSaving ? "Guardando..." : "Cargando..."} spinning={isLoading || isSaving}>
      {isLoading ? (
        <Skeleton loading />
      ) : (
        <ChemicalSubstanceForm form={form} />
      )}
    </Spin>
  </Modal>
}

export default ChemicalSubstances;
