import React, { useState } from "react";
import { Form, Input, Space, Divider, InputNumber, Row, Col, DatePicker, TimePicker, Button, Radio, Checkbox, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ResourceContext } from "common/form/context";
import { v4 as uuid } from "uuid";

const ResourceForm = ({ dataSourceApi, form }) => {
  const resourceId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: dataSourceApi }}>
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
      >
        <Divider orientation="left">Datos Generales</Divider>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="project_name"
              label="Proyecto"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="id"
              label="Folio"
              initialValue={resourceId}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="requested_by"
              label="Solicitante"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="company_or_department"
              label="Compañía / Departamento"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Descripción"
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="work_area"
              label="Área de Trabajo"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="supervisor"
              label="Responsable Interno"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="execution_expected_at__date"
              label="Fecha Estimada"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="execution_expected_at__time"
              label="Hora Estimada"
            >
              <TimePicker format="h:mma" minuteStep={15} use12Hours />
            </Form.Item>
            <Form.Item
              name="execution_expected_duration"
              label="Duración Estimada"
              style={{ width: 200 }}
            >
              <Input placeholder="Ej. 1 hora, 4 días, ..." />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ResourceContext.Provider>
  );
};

export default ResourceForm;
