import React, { useState } from "react";
import { Alert, Form, Input, Space, Divider, InputNumber, Row, Col, DatePicker, TimePicker, Button, Radio, Checkbox, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ResourceContext } from "common/form/context";
import { v4 as uuid } from "uuid";

const ResourceForm = ({ dataSourceApi, form }) => {
  const resourceId = form.getFieldValue("id") || uuid();
  const { is_approved, comments } = form.getFieldValue("feedback") || {};

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: dataSourceApi }}>
      {is_approved != undefined && (is_approved ? (
        <Alert message="Aprobado" type="success" />
      ) : (
        <Alert
          message="Denegado"
          description={`Comentarios: ${comments}`}
          type="error"
        />
      ))}

      <Form
        name="SecurityAnalysisForm"
        layout="vertical"
        form={form}
        autoComplete="off"
      >
        <Divider orientation="left">Datos Generales</Divider>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="project_name"
              label="Proyecto"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="id"
              label="Folio"
              initialValue={resourceId}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="requested_by"
              label="Solicitante"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="company_or_department"
              label="Compañía / Departamento"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Descripción"
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="work_area"
              label="Área de Trabajo"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="supervisor"
              label="Responsable Interno"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="execution_expected_at__date"
              label="Fecha Estimada"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="execution_expected_at__time"
              label="Hora Estimada"
            >
              <TimePicker format="h:mma" minuteStep={15} use12Hours />
            </Form.Item>
            <Form.Item
              name="execution_expected_duration"
              label="Duración Estimada"
              style={{ width: 200 }}
            >
              <Input placeholder="Ej. 1 hora, 4 días, ..." />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Divider orientation="left">Tipo de Actividad</Divider>
            <Form.Item
              name="is_routine"
            >
              <Radio.Group>
                <Space direction="vertical" align="baseline">
                  <Radio value="true">Rutinaria</Radio>
                  <Radio value="false">No rutinaria</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Divider orientation="left">Clasificación</Divider>
            <Form.Item
              name="category"
            >
              <Checkbox.Group>
                <Space direction="vertical" align="baseline">
                  <LabeledCheckbox value="Obra Civil" />
                  <LabeledCheckbox value="Revisiones en general" />
                  <LabeledCheckbox value="Limpieza y mantenimiento de edificios y áreas" />
                  <LabeledCheckbox value="Instalación e intervención de maquinaria de proceso" />
                  <LabeledCheckbox value="Instalación e intervención de ingeniería o servicios" />
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Pre-evaluación de Riesgos y Peligros</Divider>
        <Row>
          <Col span={8}>
            <Risks name="epp" label="A. EPP" dataSource={Options.EPP} />
          </Col>
          <Col span={8}>
            <Risks name="dangers" label="B. Peligros del Área y Trabajo" dataSource={Options.Dangers} />
          </Col>
          <Col span={8}>
            <Risks name="permission" label="C. Permiso por Tramitar" dataSource={Options.Permission} />
          </Col>
          <Col span={8}>
            <Risks name="cleaning" label="D. Orden y Limpieza" dataSource={Options.Cleaning} />
          </Col>
          <Col span={8}>
            <Risks name="complementary_training" label="E. Capacitaciones Adicionales" dataSource={Options.ComplementaryTraining} />
          </Col>
        </Row>

        <Divider orientation="left">Análisis de Seguridad en el Trabajo</Divider>
        <MultipleActivitiesInput form={form} />
      </Form>
    </ResourceContext.Provider>
  );
};

const Risks = ({ name, label, dataSource, ...props }) => (
  <>
    <Space direction="vertical" align="baseline">
      <Form.Item {...props} label={label} name={name} style={{ marginBottom: 0 }}>
        <Checkbox.Group>
          <Space direction="vertical" align="baseline">
            {dataSource.map((risk, i) => (
              <Checkbox key={i} value={risk}>{risk}</Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item {...props} name={`${name}_other`}>
        <Input placeholder="Otro(s)" />
      </Form.Item>
    </Space>
  </>
)

const MultipleActivitiesInput = ({ form, ...props }) => {
  const [activities, setActivities] = useState(form.getFieldValue("activities") || {});
  const formItemLayout = {
    style: {
      marginBottom: 0
    }
  }

  return <Form.List
    {...props}
    name="activities"
  >
    {(fields, { add, remove }, { errors }) => (
      <>
        <table style={{ tableLayout: "auto", marginBottom: "12px" }}>
          <thead className="ant-table-thead">
            <tr>
              <th className="ant-table-cell" width="25%">
                Listado de Actividades
                <br />(Paso a Paso)
              </th>
              <th className="ant-table-cell" width="10%">
                Tiempo de Ejecución
                <br />(0.1-12 hrs)
              </th>
              <th className="ant-table-cell" width="25%">
                Riesgos Identificados
              </th>
              <th className="ant-table-cell" width="6%">
                <Tooltip title="Nivel de Peligro">
                  N.P.
                  <br />(1-10)
                </Tooltip>
              </th>
              <th className="ant-table-cell" width="25%">
                Controles y Medidas de Seguridad
              </th>
              <th className="ant-table-cell" width="6%">
                <Tooltip title="Nivel de Riesgo de la Actividad">
                  N.R.A.
                </Tooltip>
              </th>
              <th width="3%"></th>
            </tr>
          </thead>
          <tbody className="ant-table-tbody">
            {fields.map((field) => (
              <tr key={field.key} className="ant-table-row ant-table-row-level-0">
                <td className="ant-table-cell">
                  <Form.Item
                    {...field}
                    {...formItemLayout}
                    name={[field.name, 'description']}
                    fieldKey={[field.fieldKey, 'description']}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input.TextArea rows={2} placeholder="Descripción" />
                  </Form.Item>
                </td>
                <td className="ant-table-cell">
                  <Form.Item
                    {...field}
                    {...formItemLayout}
                    name={[field.name, 'execution_time']}
                    fieldKey={[field.fieldKey, 'execution_time']}
                  >
                    <InputNumber
                      min={0.1}
                      max={12}
                      stringMode
                      precision={2}
                      step={0.25}
                      placeholder="0.1-12"
                      onChange={(executionTime) => setActivities({
                        ...activities,
                        [field.name]: {
                          ...activities[field.name],
                          "execution_time": executionTime
                        }
                      })}
                    />
                  </Form.Item>
                </td>
                <td className="ant-table-cell">
                  <Form.Item
                    {...field}
                    {...formItemLayout}
                    name={[field.name, 'risks']}
                    fieldKey={[field.fieldKey, 'risks']}
                  >
                    <Input.TextArea rows={2} placeholder="Riesgos" />
                  </Form.Item>
                </td>
                <td className="ant-table-cell">
                  <Form.Item
                    {...field}
                    {...formItemLayout}
                    name={[field.name, 'danger_level']}
                    fieldKey={[field.fieldKey, 'danger_level']}
                  >
                    <InputNumber
                      min={1}
                      max={10}
                      placeholder="1-10"
                      onChange={(dangerLevel) => setActivities({
                        ...activities,
                        [field.name]: {
                          ...activities[field.name],
                          "danger_level": dangerLevel
                        }
                      })}
                    />
                  </Form.Item>
                </td>
                <td className="ant-table-cell">
                  <Form.Item
                    {...field}
                    {...formItemLayout}
                    name={[field.name, 'mitigation']}
                    fieldKey={[field.fieldKey, 'mitigation']}
                  >
                    <Input.TextArea rows={2} placeholder="Medidas de Seguridad" />
                  </Form.Item>
                </td>
                <td className="ant-table-cell">
                  {(activities[field.name] && activities[field.name]["danger_level"] && activities[field.name]["execution_time"]) ? (
                    parseFloat(activities[field.name]["danger_level"] * activities[field.name]["execution_time"]).toFixed(2)
                  ) : (
                    "-"
                  )}
                </td>
                <td className="ant-table-cell">
                  <MinusCircleOutlined
                    onClick={() => remove(field.name)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => add()}
            style={{ width: '400px' }}
            icon={<PlusOutlined />}
          >
            Agregar Actividad
          </Button>
          <Form.ErrorList errors={errors} />
        </Form.Item>
      </>
    )}
  </Form.List>
}

const Options = {
  EPP: [
    "Casco con barbiquejo",
    "Guantes adecuados para la actividad",
    "Equipo de soldador",
    "Mascarilla de vapores",
    "Mascarilla de humos",
    "Mascarilla de polvos",
    "Protección auditiva",
    "Lentes, goggles",
    "Careta/pantalla",
    "Calzado de seguridad",
    "Ropa de protección",
    "Sistema contra Caídas",
    "Equipo dieléctrico",
  ],
  Dangers: [
    "Superficie resbalosa",
    "Superficies punzocortantes",
    "Ruido elevado (+80 dB)",
    "Iluminación deficiente",
    "Iluminación incandescente",
    "Electricidad viva en cercanía",
    "Tránsito de vehículos",
    "Tránsito de personal",
    "Contaminación de producto",
    "Horario restringido o limitado",
    "Materiales peligrosos o combustibles",
    "Factores ergonómicos",
    "Equipo en movimiento",
    "Uso de equipos de poder",
    "Personal en nivel inferior o superior",
  ],
  Permission: [
    "General",
    "Trabajo en alturas",
    "Chispa y flama",
    "Espacio confinado",
    "Maniobra de riesgo con SQP",
    "Trabajo eléctrico",
    "Maniobra de carga suspendida",
    "Maquinaria pesada",
    "Intervención de tubería o ducto",
    "Excavación",
    "Obra civil",
    "Temperaturas extremas",
    "Autorización LOTOTO",
  ],
  Cleaning: [
    "El área se encuentra limpia y ordenada",
    "Materiales correctamente almacenados",
    "Clasificación y separación de los materiales y residuos",
    "No se identificaron derrames previos al inicio de la actividad",
    "El área se encuentra lista para comenzar la actividad",
  ],
  ComplementaryTraining: [
    "Plataforma",
    "Maniobrista / Gruista",
    "Uso de materiales peligrosos",
    "Operación de maquinaria",
    "Manejo del CO2",
    "Montacarguista",
    "Uso de EPP",
  ]
}

const LabeledCheckbox = (props) => (
  <Checkbox {...props}>{props.value}</Checkbox>
)

export default ResourceForm;
