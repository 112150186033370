import React from "react";
import { useLocation } from "react-router";
import { Alert } from "antd";

const prodSafeProps = () => (
  isDevEnv() ? (
    {}
  ) : ({
    message: "Error al cargar la aplicación",
    description: "Favor de contactar a un administrador si el error persiste."
  })
);

class StaticErrorBoundary extends React.Component {
  state = { error: undefined };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch() {
    this.props.setTrackPathChange(true);
  }

  render() {
    if (this.state.error) {
      return <Alert
        message={this.state.error.message}
        description={this.state.error.stack}
        type="error"
        {...prodSafeProps()}
      />
    } else {
      return this.props.children;
    }
  }
}

const ErrorBoundary = ({ children }) => {
  const [key, setKey] = React.useState(0);
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);
  const [trackPathChange, setTrackPathChange] = React.useState(false);

  React.useEffect(() => {
    if (trackPathChange && previousPathname !== pathname) {
      setKey((key) => key + 1);
      setTrackPathChange(false);
    }
  }, [trackPathChange, previousPathname, pathname]);

  return (
    <StaticErrorBoundary key={key} setTrackPathChange={setTrackPathChange}>
      {children}
    </StaticErrorBoundary>
  );
};

function usePrevious(value) {
  const ref = React.useRef(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const isDevEnv = () => (
  window.location.host.substring(0,10) == "localhost:"
)

export default ErrorBoundary;
