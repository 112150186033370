import React from "react";
import { orderBy } from "lodash";
import { Index } from "common/crud/index";
import VehicleForm from "./form";
import { vehiclesApi } from "contractors/api";

const columns = [
  {
    title: "Tipo de Vehículo",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "Marca",
    key: "make",
    dataIndex: "make",
  },
  {
    title: "Código de Identificación",
    dataIndex: "identification_code",
    key: "identification_code"
  },
  {
    title: "Color",
    dataIndex: "color",
    key: "color"
  },
  {
    title: "Capacidad",
    dataIndex: "capacity",
    key: "capacity"
  },
  {
    title: "Vigencia Seguro",
    dataIndex: "insurance_policy_expires_at",
    key: "insurance_policy_expires_at",
    render: (_text, { insurance_policy_expires_at }) => (
      insurance_policy_expires_at && insurance_policy_expires_at.format("ll")
    )
  }
];

export default () => (
  <Index
    columns={columns}
    dataSourceApi={vehiclesApi}
    sortFn={(data) => orderBy(data, "created_at", "desc")}
    newLabel="Nuevo Vehículo"
    editLabel="Editar Vehículo"
    ResourceForm={VehicleForm}
  />
);
