import React from "react";
import { Switch, Route, Link as RouterLink } from "react-router-dom"
import { Menu as AntMenu } from "antd";
const { SubMenu: AntSubMenu } = AntMenu;

const SubMenu = ({ basePath, routes, ...props }) => (
  <AntSubMenu {...props}>
    {routes.map(route => (
      <Link key={basePath + route.path}>
        {route.name}
      </Link>
    ))}
    {props.children}
  </AntSubMenu>
)

const Routes = ({ basePath, routes }) => (
  <Switch>
    {routes.map(({ component: RouteComponent, ...route }, i) => (
      <Route key={i} path={basePath + route.path} exact>
        <RouteComponent />
      </Route>
    ))}
  </Switch>
)

const Link = ({ children, ...props }) => (
  <AntMenu.Item {...props}>
    <RouterLink to={props.eventKey}>
      {children}
    </RouterLink>
  </AntMenu.Item>
)

export { SubMenu, Routes };
