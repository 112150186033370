import React, { useEffect, useState } from "react";
import { Table, Space, Modal, Button, Form, Tag, Spin, Skeleton, Typography } from "antd";
import { FolderOpenOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  getContractors,
  getContractor,
  destroyContractor,
  createOrUpdateContractor
} from "admins/api";
import ContractorForm from "./form";
const { confirm } = Modal;
const { Text } = Typography;

const Contractors = () => {
  const [dataSource, setDataSource] = useState([]);
  const [editableContractorId, setEditableContractorId] = useState();
  const [isContractorFormOpen, setIsContractorFormOpen] = React.useState(false);

  useEffect(() => {
    getContractors().then((contractors) => {
      setDataSource(contractors.sort(byName));
    });
  }, []);

  const columns = [
    {
      title: "Razón Social",
      dataIndex: "business_name",
      key: "business_name"
    },
    {
      title: "Especialidad",
      dataIndex: "industry",
      key: "industry"
    },
    {
      title: "Responsable",
      dataIndex: "contact_name",
      key: "contact_name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <Text style={{ width: "100px" }} ellipsis>{text}</Text>
      ),
    },
    {
      title: "Teléfono",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Cumplimiento",
      dataIndex: "compliance",
      key: "compliance",
      render: (text) => (
        <Tag color="blue">{text || 0}%</Tag>
      ),
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_text, record) => (
        <Space size="middle">
          <a href={`/sign_in?contractor_id=${record.id}`} target="_blank">
            <FolderOpenOutlined />
          </a>
          <a><EditOutlined onClick={() => startEditMode(record)} /></a>
          <a>
            <DeleteOutlined onClick={() => handleDestroy(record)} />
          </a>
        </Space>
      ),
    },
  ];

  function startEditMode(record) {
    setEditableContractorId(record.id);
    setIsContractorFormOpen(true);
  };

  function handleDestroy(record) {
    confirm({
      title: `Deseas eliminar al Contratista?`,
      icon: <ExclamationCircleOutlined />,
      content: `- ${record.business_name}`,
      okType: "danger",
      okText: "Eliminar",
      cancelText: "Cancelar",
      transitionName: "",
      maskTransitionName: "",
      onOk() {
        destroyContractor(record.id).then(() => {
          setDataSource(dataSource.filter(contractor => contractor.id !== record.id))
        });
      }
    });
  };

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} rowKey="id" />
      <Button type="primary" onClick={() => setIsContractorFormOpen(true)}>
        Nuevo Contratista
      </Button>
      {isContractorFormOpen &&
        <CreateOrUpdateContractor
          id={editableContractorId}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />}
    </div>
  );

  function handleCreate(formData) {
    setDataSource(prevDataSource =>
      [formData, ...prevDataSource].sort(byName)
    );
  }

  function handleUpdate(formData) {
    setDataSource(prevDataSource =>
      prevDataSource.map(contractor => (
        contractor.id === editableContractorId ? (
          { ...formData, compliance: contractor.compliance }
        ) : (
          contractor
        )
      ))
    );
    setEditableContractorId(undefined);
  }

  function handleCancel() {
    setIsContractorFormOpen(false);
    setEditableContractorId(undefined);
  }
};

const CreateOrUpdateContractor = ({ id, ...props }) => {
  const [form] = Form.useForm();
  const [editableContractor, setEditableContractor] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (id) {
      getContractor(id).then((contractor) => {
        setEditableContractor(contractor);
        form.setFieldsValue(contractor);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }

    return () => {
      if (id) {
        setEditableContractor(undefined);
      }
      setIsLoading(false);
      setIsSaving(false);
    };
  }, []);

  const handleCreateOrUpdate = (formData) => {
    setIsSaving(true);

    const isUpdate = () => (
      !!editableContractor
    );

    return createOrUpdateContractor(formData).then((contractor) => {
      setIsSaving(false);
      if (isUpdate()) {
        props.handleUpdate(formData);
      } else {
        props.handleCreate({ ...formData, id: contractor.id });
      }
    });
  };

  const reset = () => {
    form.resetFields();
    setEditableContractor(undefined);
    props.handleCancel();
  }

  return <Modal
    title={`${editableContractor ? "Editar" : "Nuevo"} Contratista`}
    visible={true}
    keyboard={false}
    okText="Guardar"
    onOk={() => {
      form.validateFields()
        .then(handleCreateOrUpdate)
        .then(reset)
        .catch(_ => {})
    }}
    cancelText="Cancelar"
    onCancel={reset}
    confirmLoading={isSaving}
    destroyOnClose
  >
    <Spin tip={isSaving ? "Guardando..." : "Cargando..."} spinning={isLoading || isSaving}>
      {isLoading ? (
        <Skeleton loading />
      ) : (
        <ContractorForm form={form} />
      )}
    </Spin>
  </Modal>
}

const byName = (a, b) => (
  ("" + a.business_name).localeCompare(b.business_name)
);

export default Contractors;
