import React from "react";
import { Form, Input } from "antd";
import { Attachment } from "common/form/attachment";
import { ResourceContext } from "common/form/context";
import { v4 as uuid } from "uuid";

const ResourceForm = ({ dataSourceApi, form }) => {
  const resourceId = form.getFieldValue("id") || uuid();

  return (
    <ResourceContext.Provider value={{ id: resourceId, api: dataSourceApi }}>
      <Form
        name="ToolForm"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          name="id"
          initialValue={resourceId}
          hidden
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label="Tipo"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="brand"
          label="Marca"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="identification_code"
          label="Código de Identificación"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Color"
          name="color"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Capacidad"
          name="capacity"
        >
          <Input />
        </Form.Item>
        <Attachment label="Ficha Técnica" name="data_sheet" />
      </Form>
    </ResourceContext.Provider>
  );
};

export default ResourceForm;
