import React from "react";
import { FolderOpenOutlined } from "@ant-design/icons";
import { SubMenu, Routes } from "common/layout/menu";
import RequirementsForm from "contractors/components/records/requirements";
import Workers from "contractors/components/records/workers/index";
import ChemicalSubstances from "contractors/components/records/chemical_substances/index";
import Vehicles from "contractors/components/records/vehicles/index";
import Tools from "contractors/components/records/tools/index";

const basePath = "/expedientes";
const routes = [
  {
    name: "Contratista",
    path: "/",
    component: RequirementsForm
  },
  {
    name: "Trabajadores",
    path: "/trabajadores",
    component: Workers
  },
  {
    name: "Sustancias Químicas",
    path: "/quimicos",
    component: ChemicalSubstances
  },
  {
    name: "Vehículos",
    path: "/vehiculos",
    component: Vehicles
  },
  {
    name: "Equipo y Herramientas",
    path: "/herramientas",
    component: Tools
  }
]

const SubMenuImpl = (props) => (
  <SubMenu
    {...props}
    title={`Expedientes`}
    icon={<FolderOpenOutlined />}
    basePath={basePath}
    routes={routes}
  />
)

const RoutesImpl = () => (
  <Routes basePath={basePath} routes={routes} />
)

export { SubMenuImpl as SubMenu, RoutesImpl as Routes }
