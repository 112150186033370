import moment from "moment";
import { getFileList } from "common/form/attachment";

const crud = (resource) => ({
  index: async () => (
    fetch(`/api/${resource}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(resources => resources.map(format))
  ),

  get: async (id) => (
    fetch(`/api/${resource}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(format)
  ),
});

const format = (resource) => {
  for (const [key, value] of Object.entries(resource)) {
    if (!value) continue
    if (isDateTime(key)) {
      resource[key] = moment(value)
    }
  }

  if (resource.hasOwnProperty("attachments")) {
    resource.attachments = [...resource.attachments].reduce((obj, attachment) => {
      obj[attachment.id] = getFileList(resource.id, attachment);
      return obj;
    }, {});
  }

  return resource;
}

const isDateTime = (key) => (
  key.slice(-3) === "_at" || key.slice(-6) === "__date" || key.slice(-6) === "__time"
)

export { crud }
