import React from "react";
import { Form, Checkbox as AntCheckbox } from "antd";

const Checkbox = ({ id, name, ...checkboxProps }) => (
  <Form.Item
    name={id}
    valuePropName="checked"
  >
    <AntCheckbox {...checkboxProps}>{name}</AntCheckbox>
  </Form.Item>
);

export { Checkbox };
